import styled from '@emotion/styled';
import { mq } from './breakpoints';
import { css } from '@emotion/css';
import { ZIndex } from './z-index';
import { ThemeProps } from '@theme/base';

export const SearchPadding = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  padding: `0 ${theme.custom.spacing.large}px`,
  zIndex: 1,
  [mq.large]: {
    padding: '0 3%'
  }
}));

export const SearchResultsPadding = styled(SearchPadding)({
  display: 'block',
  [mq.medium]: {
    display: 'flex'
  }
});

export const SearchResultsContainer = styled.div(({ theme }: ThemeProps) => ({
  height: '100%',
  [mq.medium]: {
    width: '100%',
    marginRight: theme.custom.spacing.large
  }
}));

export const FiltersContainer = styled.div({
  display: 'none',
  [mq.medium]: {
    display: 'block',
    width: 400
  }
});

export const FiltersBarContainer = styled.div(({ theme }: ThemeProps) => ({
  position: 'relative',
  marginTop: `${40 + theme.custom.spacing.medium * 2}px`,
  paddingBottom: theme.custom.spacing.xxLarge
}));

export const filtersDrawerStyles = {
  paper: css({
    width: '100vw',

    [mq.small]: {
      width: 350
    }
  }),
  root: css({
    [mq.medium]: {
      display: 'none'
    }
  }),
  modal: css({
    [mq.medium]: {
      display: 'none'
    }
  })
};

export const FiltersDrawerStickyBar: any = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  right: 0,
  border: 'none',
  top: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.custom.spacing.medium,
  width: '100%',
  backgroundColor: theme.custom.colors.white,
  zIndex: ZIndex.FILTERS_FIXED_HEADER,
  borderBottom: `1px solid ${theme.custom.colors.group10.light}`,
  [mq.small]: {
    maxWidth: theme.custom.contentSizes.drawerWidth,
    borderLeft: `1px solid ${theme.custom.colors.group10.light}`
  },

  ['i']: {
    cursor: 'pointer',
    transition: `color ${theme.custom.transitions.default}`,

    ['&:hover']: {
      color: theme.custom.colors.group1.darker
    }
  }
}));

export const FiltersTitle = styled.h5(({ theme }: ThemeProps) => ({
  display: 'block',
  ...(theme.custom.typography.h5 as any),
  padding: '2px 0',
  margin: 0
}));
